<template>
  <v-container fluid ma-0 pa-0>
    <v-layout row wrap primary>
      <v-flex xs12>
        <v-img
          position="center bottom"
          :src="image"
          :lazy-src="image"
          :contain="$vuetify.breakpoint.xsOnly"
          :max-height="$vuetify.breakpoint.xsOnly ? 'auto' : '70vh'"
          min-height="580px"
          :class="{'align-center': $vuetify.breakpoint.smAndUp, 'pt-3': $vuetify.breakpoint.xsOnly}"
        >
          <v-layout wrap max-width-1400 full-width ma-auto px-3>
            <v-flex xs12>
              <p
                class="graphik-light white--text max-width-600"
                :class="{'font-36 mb-5': $vuetify.breakpoint.smAndUp, 'font-25 text-xs-center': $vuetify.breakpoint.xsOnly}"
              >{{ $ml.get('landing_title') }} <span class="graphik-bold">{{ $ml.get('landing_title_seconds') }}</span></p>

              <p v-if="$vuetify.breakpoint.smAndUp" class="graphik-light font-25 white--text">{{ $ml.get('landing_description') }} <span class="graphik-bold">{{ $ml.get('landing_description_protect') }}</span></p>

              <p
                v-if="$vuetify.breakpoint.xsOnly"
                class="graphik-light text-xs-center white--text font-16"
              >{{ $ml.get('content_benefits_step_1_description') }}</p>
            </v-flex>

            <v-flex xs12 text-xs-center v-if="$vuetify.breakpoint.xsOnly">
              <v-btn 
                color="pantene"
                depressed dark large
                :loading="g_isLoading"
                class="normalcase graphik-bold-italic ma-0 border-radius-5"
                @click="$vuetify.goTo('#products')"
              >{{ $ml.get('general_know_more') }} <v-icon dark small class="ml-2">arrow_downward</v-icon></v-btn>
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
    </v-layout>

    <p
      v-if="$vuetify.breakpoint.xsOnly"
      id="products"
      class="graphik-light font-18 secondary--text text-xs-center mb-5"
    >{{ $ml.get('landing_description') }} <span class="graphik-bold">{{ $ml.get('landing_description_protect') }}</span></p>

    <products :class="{'margin-top--140': $vuetify.breakpoint.smAndUp}" class="px-3" />

    <benefits v-if="$vuetify.breakpoint.smAndUp" />

    <v-layout v-if="$vuetify.breakpoint.smAndUp" max-width-1400 full-width ma-auto py-5 px-3>
      <v-flex xs12>
        <v-divider></v-divider>
      </v-flex>
    </v-layout>

    <payment-methods class="px-3" />

    <benefits-purple />

    <reviews />

    <template v-if="$vuetify.breakpoint.smAndUp">
      <p class="graphik-medium text-xs-center font-28 secondary--text my-3">{{ $ml.get('content_products_title') }}</p>

      <p class="graphik-light text-xs-center font-18 my-3">{{ $ml.get('content_products_description') }}</p>

      <products class="px-3 my-5" />
    </template>
  </v-container>
</template>

<script>
export default {
  name: 'Landing',
  components: {
    'payment-methods': () => import(/* webpackPrefetch: true */ '../components/ContentPaymentMethods'),
    'benefits': () => import(/* webpackPrefetch: true */ '../components/ContentBenefits'),
    'benefits-purple': () => import(/* webpackPrefetch: true */ '../components/ContentBenefitsPurple'),
    'reviews': () => import(/* webpackPrefetch: true */ '../components/ContentReviews'),
    'products': () => import(/* webpackPrefetch: true */ '../components/ContentProducts')
  },
  computed: {
    image () {
      return this.$vuetify.breakpoint.xsOnly ? require('@/assets/img/images/cuco_banner_landing_mobile.png') : require('@/assets/img/images/cuco_banner_landing.png')
    }
  }
}
</script>

<style scoped>
</style>
